body {
	background-color: $bodycolor;
	font-family: $base-font;
	color: $text-color;
}
.navigation-wrapper {
	@include container;
	padding: 2em 0 1em;
	font-family: $heading-font;
	font-weight: 700;
	text-transform: uppercase;
	@include clearfix;
}
.site-name {
	@include grid(12,10);
	@include prefix(12,1);
	@include suffix(12,1);
	margin-bottom: 1em;
	float: none;
	display: block;
	@include font-rem(24);
	@media #{$small} {
		@include grid(12,2);
		@include prefix(12,0.5);
		@include suffix(12,0.5);
		@include font-rem(16);
	}
	@media #{$x-large} {
		@include grid(12,1.5);
		@include prefix(12,2);
	}
}
.top-navigation {
	@include grid(12,10);
	@include prefix(12,1);
	@include suffix(12,1);
	margin-bottom: 1em;
	float: none;
	display: block;
	@media #{$small} {
		@include grid(12,9);
		@include prefix(12,0);
		@include suffix(12,0);
	}
	@media #{$x-large} {
		@include grid(12,8);
	}
	ul {
		margin: 0;
		padding: 0;
		clear: both;
		list-style-type: none;
	}
	li {
		display: block;
		list-style-type: none;
		border-bottom: 1px solid lighten($black,80);
		border-bottom: 1px solid fade($black,10);
		@include font-rem(16);
		&:last-child {
			border-bottom: 0 solid transparent;
		}
		@media #{$small} {
			display: inline;
			margin-right: 25px;
			white-space: nowrap;
			border-bottom: 0 solid transparent;
		}
		a { 
			display: block;
			padding: 10px 0;
			decoration: none;
			border-bottom: 0 solid transparent;
			@include transition(all .2s);
			@media #{$small} {
				display: inline;
				padding: 0;
			}
		}
	}
}
// Navicon lines
$button-size: 1.5rem;
@mixin navicon-line() {
	display: inline-block;
	width: $button-size;
	height: $button-size/7;
	// line color
	background: $white;
	border-radius: $button-size/14;
	transition: .3s;
}
.navicon-lines-button {
	padding: $button-size/4 $button-size/2;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	border-radius: $button-size/7;
}
.navicon-lines-button:hover {
	opacity: 1;
}
.navicon-lines-button:active {
	transition: 0;
}
.navicon-lines {
	margin-right: 10px;
	margin-bottom: $button-size/5;
	// create middle line
	@include navicon-line;
	position: relative;
	// create the upper and lower lines as pseudo-elements of the middle line
	&:before,
	&:after {
		@include navicon-line;
		position: absolute;
		left: 0;
		content: '';
		-webkit-transform-origin: $button-size/14 center;
		transform-origin: $button-size/14 center;
	}
	&:before { top: $button-size/4; }
	&:after { top: -$button-size/4; }
}
.navicon-lines-button:hover {
	opacity: 1;
	.navicon-lines {
		&:before { top: $button-size/3.5; }
		&:after { top: -$button-size/3.5; }
	}
}
.navicon-lines-button.x.active .navicon-lines {
	// hide the middle line
	background: transparent;
	// overlap the lines by setting both their top values to 0
	&:before,
	&:after {
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		top: 0;
		width: $button-size;
	}
	// rotate the lines to form the x shape
	&:before {
		-webkit-transform: rotate3d(0,0,1,45deg);
		transform: rotate3d(0,0,1,45deg);
	}
	&:after {
		-webkit-transform: rotate3d(0,0,1,-45deg);
		transform: rotate3d(0,0,1,-45deg);
	}
}
// Style the toggle menu link and hide it
.nav .navtoogle {	
	@include font-rem(18);
	font-weight: normal;
	background-color: $black;
	color: $white;	
	border: none;
	cursor: pointer;
	@media #{$small} {
		display: none;
	}
}
.nav button {
	border: none;
	background: none;
}
.navtoogle i {
	z-index:-1;
}
.icon-menu {
	position: relative;
	top: 3px;
	line-height: 0;
}
// When JavaScript is disabled, we hide the toggle button
.no-js .nav .navtoggle {
	display: none;
}
// When JavaScript is disabled, we show the menu
.no-js .nav ul {
	max-height: 30em;
	overflow: hidden;
}
// When JavaScript is enabled, we hide the menu
.js .nav ul {
	max-height: 0;
	overflow: hidden;
	@media #{$small} {
		max-height: 30em;
	}
}
// Displaying the menu when the user has clicked on the button
.js .nav .active + ul {		
	max-height: 30em;
	overflow: hidden;
	-webkit-transition: max-height .4s;
	-moz-transition: max-height .4s;
	-o-transition: max-height .4s;
	-ms-transition: max-height .4s;
	transition: max-height .4s;
}
#main {
	counter-reset: captions;
	@include container;
	@include clearfix;
	clear: both;
	margin-top: 2em;
	h1 {
		margin-top: 0;
	}
	.post,
	.page {
		@include container;
		@include grid(12,10);
		@include prefix(12,1);
		@include suffix(12,1);
		margin-bottom: 2em;
		@media #{$small} {
			@include grid(12,6);
			@include prefix(12,0);
			@include suffix(12,0);
		}
		@media #{$x-large} {
			@include grid(12,4.5);
		}
	}
}
#index {
	@include container;
	@include grid(12,10);
	@include prefix(12,1);
	@include suffix(12,1);
	margin-bottom: 2em;
	@media #{$small} {
		@include grid(12,6);
		@include prefix(12,0);
		@include suffix(12,0);
	}
	@media #{$x-large} {
		@include grid(12,4.5);
	}
	h3 {
		margin: 0;
		padding-bottom: .5em;
		@include font-rem(28);
		border-bottom: 1px solid lighten($black,70);
	}
	article {
		h2 {
			margin-bottom: 4px;
			@include font-rem(20);
			&.link-post {
				margin-bottom: 0px + $doc-line-height;
				margin-bottom: 0rem + ($doc-line-height / $doc-font-size);
			}
		}
		p {
			@include font-rem(14);
		}
		p+p {
			text-indent: 0;
		}
	}
}
.image-wrap {
	position: relative;
	margin-bottom: 2em;
	@include clearfix;
	&:after {
		content: " ";
		display: block;
		position: absolute;
		bottom: 5px;
		left: 8%;
		width: 0;
		height: 0;
		border: 10px solid transparent;
		border-bottom-color: $bodycolor;
		@media #{$small} {
			left: 25%;
		}
		@media #{$large} {
			border-width: 20px;
		}
		@media #{$x-large} {
			left: 33.333333333%;
		}
	}
	img {
		width: 100%;
		height: auto;
		-ms-interpolation-mode: bicubic;
	}
	.headline-wrap {
		position: absolute;
		bottom: 25px;
		@include prefix(12,1);
		@media #{$small} {
			left: 25%;
			margin-left: 0;
		}
		@media #{$x-large} {
			@include prefix(12,1);
		}
		h1, h2 {
			color: $white;
			@include text-shadow(0 1px 2px rgba(0,0,0,.25));
		}
	}
}
.byline {
	clear: both;
	font-size: 80%;
}
.article-author-side,
.article-author-bottom {
	h3 {
		margin-bottom: 0;
	}
	p {
		font-size: 80%;
		font-style: italic;
	}
	a, a:hover {
		border-bottom: 0 solid transparent;
	}
}
.author-social {
  display: block;
  margin-bottom: 5px;
  @include font-rem(14);
  color: $black;
  &:visited {
    color: $black;
  }
  &:hover {
    @include scale(1.1);
  }
  &:active {
    @include translate(0, 2px);
  }
  .fa {
		margin-right: 5px;
	}
}
.article-author-side {
	display: none;
	.bio-photo {
		max-width: 110px;
		@include rounded(150px);
	}
	@media #{$small} {
		display: block;
		@include grid(12,2);
		@include prefix(12,0.5);
		@include suffix(12,0.5);
		h3,
		p,
		.author-social {
			display: block;
			max-width: 125px;
		}
		h3 {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 20px;
		}
		.author-social {
			margin-bottom: 5px;
		}
	}
	@media #{$large} {
		h3,
		.bio-photo,
		p,
		.author-social {
			max-width: 150px;
		}
	}
	@media #{$x-large} {
		@include grid(12,1.5);
		@include prefix(12,2);
	}
}
.article-author-bottom {
	margin-bottom: 1em;
	@media #{$small} {
		display: none;
	}
	.bio-photo {
		float: left;
		margin-right: 25px;
		max-width: 100px;
		@include rounded(150px);
	}
	.author-social {
		display: inline-block;
		margin-right: 10px;
	}
	@media #{$large} {
		h3,
		.bio-photo,
		p,
		.author-social {
			max-width: 150px;
		}
	}
}
.article-wrap {
	// Dotted line underlines for links
	p > a,
    p > em > a,
	li > a {
		text-decoration: underline;
	}
}

.toc {
	display: none;
	font-size: 95%;
	@media #{$small} {
		display: block;
		@include grid(12,2);
		@include prefix(12,0.5);
		@include suffix(12,0.5);
		position: absolute;
		top: 5.5em;
		right: 0;
		background-color: $white;
	}
	header {
		background: lighten($black, 10);
	}
	h3 {
		margin: 0;
		padding: 5px 10px;
		color: $white;
		@include font-rem(16);
		text-transform: uppercase;
		&:hover {
			cursor: pointer;
		}
	}
	ul {
		margin: 2px 0 0;
		padding: 0;
		line-height: 1;
	}
	li {
		display: block;
		margin: 0 0 1px 0;
		padding: 0;
		font-family: $heading-font;
		list-style-type: none;
		&:last-child {
			border-bottom-width: 0;
		}
		a {
			padding: 10px;
			display: block;
			color: $white;
			text-decoration: none;
			background: lighten($black, 30);
			@include opacity(0.7);
			@include transition(opacity 0.2s ease-in-out);
			&:hover {
				@include opacity(1);
			}
		}
		ul {
			margin: 1px 0 0;
		}
	}
}
#drawer {
	height: 100%;
	overflow: hidden;
	&.js-hidden {
		max-height: 0;
	}
}
.image-grid {
	@include clearfix;
	list-style: none;
	margin: 0 0 1em;
	padding: 0;
	li {
		@include grid(12,6);
		@media #{$micro} {
			width: 33.333333%;
		}
		@media #{$small} {
			width: 25%;
		}
		@media #{$medium} {
			width: 20%;
		}
		@media #{$large} {
			width: 16.666666666%;
		}
	}
}
.recent-grid {
	@include clearfix;
	list-style: none;
	margin: 1em 0;
	li {
		display: inline;
		a { 
			border-bottom: 0 solid transparent;
			&:hover {
				border-bottom: 0 solid transparent;
			}
		}
	}
	img {
		width: 19%;
		margin-bottom: 1%;
	}
}
.holder {
	font-family: $heading-font;
	a {
		cursor: pointer;
		margin: 0 5px;
	}
	a.jp-previous {
		margin-left: 0;
		margin-right: 15px;
	}
	a.jp-next {
		margin-left: 15px;
		margin-right: 0;
	}
	a.jp-current {
		font-weight: bold;
	}
	a.jp-disabled, 
	a.jp-disabled:hover {
	}
	a.jp-current, 
	a.jp-current:hover,  
	a.jp-disabled, 
	a.jp-disabled:hover {
		cursor: default;
		background: none;
	}
	span {
		margin: 0 5px;
	}
}


.minimalism {
	// Main content container properties
	max-width: 800px;
	margin: 0 auto; // Center the content
	padding: 20px;

	a {
	  color: red;
	  &:hover {
		text-decoration: underline;
	  }
	}

	.more {
	  color: blue;
	  &:hover {
		text-decoration: underline;
	  }

	}
  }
  
  

.footer-wrap {
	@include container;
	@include clearfix;
	clear: both;
	padding-bottom: 3em;
	a,
	a:active,
	a:visited,
	p,
	h4,
	h5,
	h6,
	span { 
		@include font-rem(14);
	}
	footer {
		@include grid(12,10);
		@include prefix(12,1);
		@include suffix(12,1);
		@media #{$small} {
			@include grid(12,6);
			@include prefix(12,3);
			@include suffix(12,3);
		}
		@media #{$x-large} {
			@include grid(12,4.5);
			@include prefix(12,4);
			@include suffix(12,3.5);
		}
	}
}
.related-articles {
	@include grid(12,10);
	@include prefix(12,1);
	@include suffix(12,1);
	margin-bottom: 2em;
	@media #{$small} {
		@include grid(12,6);
		@include prefix(12,3);
		@include suffix(12,3);
	}
	@media #{$x-large} {
		@include grid(12,4.5);
		@include prefix(12,4);
		@include suffix(12,3.5);
	}
	h4 {
		
		text-transform: uppercase;
		margin-bottom: 0;
	}
	li {
		margin-bottom: 0;
	}
}
.footer-icons {
	li {
		display: inline-block;
		padding-right: 5px;
		a {
			color: lighten($basecolor, 40%);
			@include transition(.4s linear);
			&:hover {
				text-decoration: none;
				border-bottom: none;
				.icon-twitter,
				.icon-twitter-sign {
					color: #2aa9df;
				}
				.icon-facebook,
				.icon-facebook-sign {
					color: #3a5a98;
				}
				.icon-instagram,
				.icon-instagram-sign {
					color: #306088;
				}
				.icon-google-plus,
				.icon-google-plus-sign {
					color: #b63324;
				}
				.icon-lastfm,
				.icon-lastfm-sign {
					color: #d80049;
				}
				.icon-github {
					color: #4183c4;
				}
			}
		}
	}
}

// Browser Upgrade
// --------------------------------------------------
.browser-upgrade {
	background: #000;
	text-align: center;
	margin: 0 0 2em 0;
	padding: 10px;
	text-align: center;
	color: $white;
	a {
		color: $white;
		border-bottom: 1px dotted $white;
		text-decoration: none;
		&:hover {
			border-bottom: 1px solid $white;
		}
	}
}

// Google Search
// --------------------------------------------------
#goog-fixurl {
	ul {
		list-style: none;
		margin-left: 0;
		padding-left: 0;
		li {
			list-style-type: none;
		}
	}
}
#goog-wm-qt {
	width: auto;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 8px 20px;
	display: inline-block;
	@include font-rem(14);
	background-color: $white;
	color: $black;
	border-width: 2px !important;
	border-style: solid !important;
	border-color: lighten($black,50);
	@include rounded(3px);
}
#goog-wm-sb {
	@extend .btn;
}
